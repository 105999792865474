import React from "react";
import { Link } from "react-router-dom";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsFillEnvelopeFill,
  BsFillTelephoneFill,
} from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";

function Footer() {
  return (
    <footer className="mt-10 w-full  bg-zinc-200  bg-opacity-40 ">
      <section className="mx-auto flex max-w-[1200px] flex-wrap px-2 py-8">
        <div className="my-5 w-full pl-4 md:pl-0 md:text-left lg:w-1/3 ">
          <h3 className="mb-3 text-2xl text-pink-400">Quick Links</h3>
          <ul>
            <li className="my-2 hover:text-pink-400">
              {" "}
              <Link to="/about">About Us</Link>{" "}
            </li>
            <li className="my-2 hover:text-pink-400">
              {" "}
              <Link to="/contact">Contact Us</Link>{" "}
            </li>
            <li className="my-2 hover:text-pink-400">
              {" "}
              <span>Donate</span>{" "}
            </li>
            {/* <li className="my-2 hover:text-pink-400">
              {' '}
              <Link href="/services">Services</Link>{' '}
            </li> */}
          </ul>
        </div>

        <div className="my-5 w-full pl-4 md:pl-0 md:text-left lg:w-1/3">
          <h3 className="mb-3 text-2xl text-pink-400">Follow Us</h3>
          <ul>
            <li className="my-2 hover:text-pink-400">
              {" "}
              <a href="" className="mb-4 flex items-center">
                <span>
                  {" "}
                  <BsInstagram />
                </span>{" "}
                <span className="ml-3">Instagram</span>
              </a>{" "}
            </li>
            <li className="my-2 hover:text-pink-400">
              {" "}
              <a href="" className="mb-4 flex items-center">
                <span>
                  {" "}
                  <BsFacebook />
                </span>{" "}
                <span className="ml-3">Facebook</span>
              </a>
            </li>
            <li className="my-2 hover:text-pink-400">
              {" "}
              <a href="" className="mb-4 flex items-center">
                <span>
                  {" "}
                  <BsLinkedin />
                </span>{" "}
                <span className="ml-3">Linkedin</span>
              </a>
            </li>
          </ul>
        </div>

        <div className="my-5 w-full pl-4 md:pl-0 md:text-left lg:w-1/3">
          <h3 className="mb-3 text-2xl text-pink-400">Contact Us</h3>
          <ul>
            <li className="my-2 hover:text-pink-400">
              {" "}
              <a href="tel:12028881145" className="mb-4 flex items-center">
                <span>
                  {" "}
                  <BsFillTelephoneFill />
                </span>{" "}
                <span className="ml-3">12028881145</span>
              </a>{" "}
            </li>
            <li className="my-2 hover:text-pink-400">
              {" "}
              <a className="mb-4 flex items-center" href="">
                <span>
                  <MdLocationOn />
                </span>
                <span className="ml-3"> Houston, Texas</span>
              </a>{" "}
            </li>
            <li className="my-2 hover:text-pink-400">
              {" "}
              <a
                href="mailto:admin@awhpi.com"
                className="mb-4 flex items-center"
              >
                <BsFillEnvelopeFill />{" "}
                <span className="ml-4">admin@awhpi.com</span>
              </a>{" "}
            </li>
          </ul>
        </div>

        <div className="w-full border-t pl-4 pt-5 text-center md:pl-0">
          &copy; 2022 AWHPI
        </div>
      </section>
    </footer>
  );
}

export default Footer;
