import React from "react";
import Container from "../components/Container";
import team from "../images/team.png";
function about() {
  return (
    <main>
      <section className="h-full bg-pink-800 py-12 px-3 text-zinc-100">
        <Container>
          <div className="text-center">
            <h3 className=" my-7 ml-3 w-full text-3xl uppercase  underline ">
              who we are
            </h3>
            <h1 className=" my-5 mx-auto  max-w-xl text-3xl uppercase  lg:text-5xl">
              providing quality healthcare for women FOR OVER 20+ YEARS
            </h1>
            <p className="mx-auto mt-10 w-fit max-w-lg">
              We’re nonprofit organization dedicated to bringing health and
              healing to citizens in impoverished areas.
            </p>
          </div>
        </Container>
      </section>

      <Container>
        <section className="my-24 text-center ">
          <h3 className=" my-7 ml-3 w-full text-3xl uppercase  text-pink-800 underline">
            OUR MISSION & HISTORY
          </h3>
          <p className="mx-auto mt-10 w-fit max-w-lg text-zinc-700">
            AWHPI envisions a world where all women have unrestricted access to
            premium healthcare We were founded over 20 years ago to fill a
            critical gap in the healthcare sector for women. Today, we are the
            leading in helping women get good healthcare,worldwide.
          </p>
        </section>
      </Container>

      <section className="h-full bg-pink-800  px-3 py-20 text-zinc-100">
        <Container>
          <div className="flex flex-wrap">
            <div className="w-full text-center lg:w-1/2">
              <h3 className=" my-5 ml-3 w-full text-2xl uppercase  underline ">
                Our values
              </h3>

              <p className="mx-auto mt-5 w-fit max-w-lg">
                Our values, rooted in feminist funding principles, guide every
                interaction we have with our partners, donors, community
                members, and colleagues.
              </p>
            </div>
            <div className="w-full text-center lg:w-1/2">
              <h2 className=" my-5 mx-auto  max-w-xl text-3xl uppercase  lg:text-7xl">
                BOLD, COLLABORATIVE CURIOUS & AUTHENTIC
              </h2>
            </div>
          </div>
        </Container>
      </section>

      <Container>
        <section className="my-10 py-12 text-center">
          <h3 className=" my-5 ml-3 w-full text-2xl uppercase  text-pink-800 underline ">
            ABOUT OUR TEAM
          </h3>
          <p className="mx-auto mt-10 w-fit max-w-lg text-zinc-600">
            We are a diverse team of dedicated individuals who work tirelessly
            to fund and strengthen bold, ambitious gender justice movements.
            Meet our staff, board members, and advisors.
          </p>

          <div
            className="relative mt-10 h-[300px] w-full  lg:h-[500px] "
            style={{
              backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.5)),  url("${team}") `,
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* <img src={team} alt="picture of  awhpi team" className="" /> */}
          </div>
        </section>

        <section className="h-full bg-pink-800 px-3  py-20 text-center text-zinc-100">
          <h2 className=" my-5 mx-auto  max-w-xl text-3xl uppercase  lg:text-7xl">
            MAKE AN IMPACT!
          </h2>

          <button className=" mt-5 bg-pink-400 px-6 py-4   text-base font-semibold text-zinc-50 duration-300 ease-in-out hover:bg-pink-700 ">
            DONATE NOW
          </button>
        </section>
      </Container>
    </main>
  );
}

export default about;
