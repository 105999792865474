import React, { useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaPaperPlane } from "react-icons/fa";
function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleContactForm = (event) => {
    event.preventDefault();
    const contactData = {
      name,
      subject,
      email,
      phoneNumber,
      message,
    };

    // console.log(contactData);
    setIsLoading(true);
    fetch("/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactData),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Success:", data);
        if (data.success === true) {
          setIsSent(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <section className="mx-auto  flex max-w-[1200px] flex-wrap mt-12">
      <div className="flex w-full  justify-center p-2 px-4 lg:w-1/2">
        {isSent ? (
          <div className="flex flex-col items-center justify-center">
            <BsFillCheckCircleFill className="my-5 rounded-full text-8xl text-pink-400 shadow-lg shadow-pink-400/50" />
            <h3 className="text-2xl font-medium ">Sent Successfully</h3>
          </div>
        ) : (
          <form onSubmit={handleContactForm} className="flex flex-wrap  ">
            <h1 className=" my-5 ml-3 w-full  text-4xl text-pink-800 lg:text-5xl">
              CONTACT US NOW!
            </h1>
            <div className="my-1 w-full  p-2  md:w-1/2">
              <input
                onChange={(e) => setName(e.target.value)}
                type="text"
                name="name"
                id="name"
                required
                placeholder="Name"
                className="d-block w-full rounded-md border-2 border-zinc-300 bg-transparent bg-zinc-100 px-4 py-2 text-zinc-700 focus:outline-none"
              />
            </div>

            <div className="w-full p-2  md:w-1/2">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                id="email"
                required
                placeholder="Email Adress"
                className="d-block w-full rounded-md border-2 border-zinc-300 bg-transparent bg-zinc-100 px-4 py-2 text-zinc-700 focus:outline-none"
              />
            </div>

            <div className="my-1  w-full p-2 md:w-1/2">
              <input
                type="text"
                name="phone"
                id="phone"
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                placeholder="Phone Number"
                className="d-block w-full rounded-md border-2 border-zinc-300 bg-transparent bg-zinc-100 px-4 py-2 text-zinc-700 focus:outline-none"
              />
            </div>
            <div className="my-1  w-full p-2 md:w-1/2">
              <input
                type="text"
                name="subject"
                id="subject"
                onChange={(e) => setSubject(e.target.value)}
                required
                placeholder="Subject"
                className="d-block w-full rounded-md border-2 border-zinc-300 bg-transparent bg-zinc-100 px-4 py-2 text-zinc-700 focus:outline-none"
              />
            </div>

            <div className="my-1 w-full p-2">
              <textarea
                id="message"
                name="message"
                rows={4}
                cols={5}
                required
                placeholder="Message"
                onChange={(e) => setMessage(e.target.value)}
                className="d-block w-full rounded-md border-2 border-zinc-300 bg-transparent bg-zinc-100 px-4 py-2 text-zinc-700 focus:outline-none"
              />
            </div>
            <div className="-m-t-5 w-full px-2 text-center text-white">
              {isLoading ? (
                <span className="mr-2 mb-12 mt-5 flex  w-full cursor-no-drop items-center	  justify-center rounded bg-pink-600 px-7 py-4 text-center   text-sm font-semibold  transition duration-100 ease-out hover:bg-pink-600 hover:ease-in">
                  SENDING...
                </span>
              ) : (
                <button className=" mb-12 mt-5  flex w-full   cursor-pointer items-center justify-center rounded bg-pink-400 px-7   py-4 text-center text-sm transition duration-100 ease-out hover:bg-pink-600 hover:ease-in">
                  {" "}
                  <span className="mr-2 font-semibold">SEND</span>
                  <span>
                    <FaPaperPlane className="text-xl" />
                  </span>
                </button>
              )}
            </div>
          </form>
        )}
      </div>
      <div className="w-full p-2 px-4 lg:w-1/2">
        <h3 className=" my-5 ml-3 w-full  text-4xl text-pink-800 lg:text-5xl">
          MAP YOUR WAY TO US
        </h3>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d443088.87942003453!2d-95.40129145!3d29.81717795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640b8b4488d8501%3A0xca0d02def365053b!2sHouston%2C%20TX%2C%20USA!5e0!3m2!1sen!2sng!4v1652554715406!5m2!1sen!2sng"
          width={100 + "%"}
          height={500 + "px"}
          style={{ border: 0 }}
          allowFullScreen={true}
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
}

export default ContactPage;
