import React, { useState } from "react";
import logo from "../images/logo.jpg";
// import Image from 'next/image'
import { FiMenu } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Container from "./Container";

function Navbar() {
  const location = useLocation();

  const navLinks = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "About",
      path: "/about",
    },
    {
      title: "Events",
      path: "/events",
    },
    {
      title: "blog",
      path: "/blog",
    },
    {
      title: "Contact",
      path: "/contact",
    },
  ];

  const [isSidebar, setIsSidebar] = useState(false);

  return (
    <header className="shadow-xs  top-0 z-50 w-full overflow-hidden bg-white py-2  shadow">
      <Container>
        <nav className=" mx-auto flex items-center justify-between px-5 md:px-3 ">
          <Link to="/">
            <span className="relative block h-16 w-16 cursor-pointer lg:h-20 lg:w-20">
              <img
                src={logo}
                // layout="fill"
                alt="AWHPI logo"
                // priority
                // loading="eager"
                // objectFit="contain"
                className="object-cover"
              />
            </span>
          </Link>

          <div className="flex">
            <div
              className={` ${
                isSidebar ? "block " : "hidden"
              } fixed inset-0 h-screen  bg-white duration-500  ease-in-out lg:relative
          lg:block lg:h-full`}
            >
              {/* <div className="relativ"> */}
              <MdOutlineClose
                className="absolute right-10 text-zinc-600 top-10 cursor-pointer text-3xl lg:hidden "
                onClick={() => setIsSidebar(false)}
              />
              {/* </div> */}

              <ul className="ml-auto -mt-10 flex h-full flex-col items-center justify-center md:-mt-0 lg:flex-row">
                {navLinks.map((navLink) => (
                  <li
                    className={`my-8 flex flex-col items-center px-4  py-2 text-base font-medium text-zinc-600 uppercase hover:text-pink-400 lg:my-1  lg:capitalize 
                    ${
                      location.pathname == navLink.path
                        ? "text-pink-400"
                        : "text-zinc-800"
                    }
                      `}
                    onClick={() => setIsSidebar(false)}
                    key={navLink.title}
                  >
                    <Link key={navLink.path} to={navLink.path}>
                      {navLink.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="ml-3 flex items-center">
              <button className=" bg-pink-400 px-4 py-2  text-base font-semibold text-zinc-50 duration-300 ease-in-out hover:bg-pink-700 ">
                DONATE
              </button>

              <FiMenu
                onClick={() => setIsSidebar(true)}
                className="ml-4 mr-1 cursor-pointer text-2xl lg:hidden text-zinc-600"
              />
            </div>
          </div>
        </nav>
      </Container>
    </header>
  );
}

export default Navbar;
