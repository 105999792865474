import React from "react";
import heroImg from "../images/hero 1.png";
import { Link } from "react-router-dom";
import Container from "../components/Container";
import princess from "../images/princess.jpg";
import team from "../images/team.png";
import Events from "../components/Events";
function Home() {
  return (
    <>
      <section className="  flex flex-row-reverse flex-wrap  overflow-hidden lg:h-[90vh] lg:flex-row">
        <div className="relative h-[300px] w-full lg:h-full  lg:w-1/2">
          <img src={heroImg} alt="hero background" />
        </div>
        <div className="relative flex h-fit w-full items-center justify-center bg-pink-800 py-14 px-5 lg:h-full lg:w-1/2">
          <div>
            <p className="font-xl mb-5 text-center text-pink-200">EST. 2003</p>
            <h1 className="text-center text-4xl uppercase text-white lg:text-7xl">
              providing quality healthcare for women FOR OVER 20+ YEARS
            </h1>
            <Link to="/about">
              <span className="mx-auto mt-16 block w-fit bg-pink-400 px-10 py-3 font-semibold  text-white hover:bg-pink-700">
                Learn More
              </span>
            </Link>
          </div>
        </div>
      </section>

      <div>
        <h5 className="my-16 mx-auto max-w-[800px] px-5 text-center text-3xl text-pink-800 lg:text-5xl">
          We’re nonprofit organization dedicated to bringing health and{" "}
          <span className="text-pink-400">healing to citizens</span> in
          impoverished areas.
        </h5>
      </div>

      <Container>
        <section className=" my-10 flex  flex-row-reverse flex-wrap lg:flex-row">
          <div className="mb-10 mt-24 w-full  text-center">
            <h2 className="text-5xl uppercase text-pink-800 md:text-6xl">
              meet our Head changeMaker
            </h2>
            <p className="mt-3 text-black">
              Learn more about a leader who is making a difference.
            </p>
          </div>
          <div
            className="relative h-[300px] w-full lg:h-[500px]   lg:w-1/2"
            style={{
              backgroundImage: ` url("${princess}") `,
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* <img
              src={princess}
              alt=" HRH Princess Dr. Moradeun Ogunlana"
             
            /> */}
          </div>
          <div className="relative flex h-fit w-full items-center justify-center  py-14 px-5 lg:h-full lg:w-1/2">
            <div className="text-center">
              <h2 className="text-3xl uppercase text-pink-800 md:text-4xl">
                HRH Princess Dr. Moradeun Ogunlana
              </h2>
              <p className="mt-3 text-black">
                She is a visionary, entrepreneur, consultant, and humanitarian.
                As a recognized leader on women empowerment for Africa in the
                United States and around the world, the Princess is descended
                from one of the oldest and most distinguished Royal Family lines
                in Lagos, Nigeria.
              </p>
              <p className="mt-3 text-black">
                Princess Moradeun is the Founder/CEO of African Women's Health
                Project International/ AWHPI Global Foundation, and
                President/CEO of the Innovative Global Consulting (IGC) Group of
                Companies. Additionally, The Princess is the official brand
                Ambassador of Adire Oodua Textiles under the Chairmanship of His
                Imperial Majesty, Oba Adeyeye Enitan Ogunwusi, OJAJA II - the
                Ooni of Ife, Nigeria - Africa's Foremost Monarch.
              </p>
              <a
                href="/"
                className="mx-auto mt-8 block w-fit bg-pink-400 px-10 py-3 font-semibold  text-white hover:bg-pink-700"
              >
                Learn More
              </a>
            </div>
          </div>
        </section>
      </Container>

      <Events />

      <Container>
        <section className=" mt-[80px] flex flex-row-reverse flex-wrap  lg:mt-[95px] lg:h-[70vh] lg:flex-row">
          <div
            className="relative h-[300px] w-full lg:h-full  lg:w-1/2 border"
            style={{
              backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.5)),  url("${team}") `,
              backgroundPosition: "center center",
            }}
          >
            {/* <img src={team} alt="awhpi team " className="object-fill" /> */}
          </div>
          <div className="relative flex h-fit w-full items-center justify-center  py-14 px-5 lg:h-full lg:w-1/2">
            <div className="text-center">
              <h2 className="text-3xl uppercase text-pink-800 md:text-4xl">
                Read Amazing content on our journey
              </h2>

              <Link to="/blog">
                <span className="mx-auto mt-8 block w-fit bg-pink-400 px-10 py-3 font-semibold  text-white hover:bg-pink-700">
                  OUR BLOG
                </span>
              </Link>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
}

export default Home;
