import React from "react";
import Events from "../components/Events";

function events() {
  return (
    <main>
      <Events />
    </main>
  );
}

export default events;
