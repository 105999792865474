import React from "react";

function EventsBlock({ image, title, date }) {
  return (
    <div className="my-24  flex flex-wrap max-w-4xl mx-auto">
      <div className="relative h-[300px] overflow-hidden w-full md:w-1/2 ">
        <img src={image} alt="event poster" className="" />
      </div>
      <div className="mt-10 flex h-full w-full items-center pl-5 md:w-1/2">
        <div>
          <h3 className="text-4xl capitalize">{title}</h3>
          <p className="mt-3">{date}</p>
        </div>
      </div>
    </div>
  );
}

export default EventsBlock;
