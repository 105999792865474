import React, { ReactChildren } from "react";
// import { Tchildren } from '../interfaces'
// type Tchildren = {
//   children: React.ReactNode
// }

function Container({ children }) {
  return (
    <>
      <section className="relative  mx-auto h-fit w-full max-w-[1400px]  text-zinc-100">
        {children}
      </section>
    </>
  );
}

export default Container;
