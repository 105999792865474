import React from "react";
import ev1 from "../images/event (1).jpeg";
import ev2 from "../images/event (2).jpeg";
import Container from "./Container";
import EventsBlock from "./EventsBlock";
import { Link } from "react-router-dom";

function Events() {
  const events = [
    {
      image: ev1,
      title: "first ladies forum",
      date: "2022-12-12",
    },
    {
      image: ev2,
      title: "menstrual hygiene day",
      date: "2022-12-12",
    },
  ];
  return (
    <section className=" bg-pink-800 py-24 px-5 text-white">
      <Container>
        {" "}
        <div className="mb-10   text-center ">
          <h2 className="text-5xl uppercase  md:text-6xl">our events</h2>
          <p className="mt-3 ">MOVEMENTS MOVE MOUNTAINS</p>
        </div>
        {events.map((ev, index) => (
          <EventsBlock image={ev.image} title={ev.title} date={ev.date} />
        ))}
        <Link to="/events">
          <span className="mx-auto mt-16 block w-fit bg-pink-400 px-24 py-3 font-semibold  text-white hover:bg-pink-700">
            SEE MORE
          </span>
        </Link>
      </Container>{" "}
    </section>
  );
}

export default Events;
