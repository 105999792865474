import React from "react";
import ReactMarkdown from "react-markdown";

function Blog() {
  return (
    <main className=" flex items-center justify-center text-5xl h-screen text-pink-800">
      <h1>COMING SOON</h1>
      <h1 className="text-5xl  text-pink-800">
        {" "}
        <ReactMarkdown># Hello, *world*!</ReactMarkdown>
      </h1>
    </main>
  );
}

export default Blog;
